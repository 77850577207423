import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '@/product/components/organisms/FullScreenPopup'
import InvoiceContext from '@/product/context/invoice/invoiceContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import { mapData } from '@/product/common/components'
import { useTranslation } from 'react-i18next'
import {
    dateFormatFunction,
    dateTimeFormatFunction,
    currencyFormat,
    capitalize,
    paymentTypeRosoom,
} from '../../custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button } from '@material-ui/core'
import CartItem from './cartItem'
// import ReactToPdf from 'react-to-pdf'
import UserContext from '@/product/context/seller/user/userContext'
import { LOGO, SITE_NAME } from '@/utils/UI/index'
import CustomDialog from '@/product/components/organisms/Dialog'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import AlertContext from '@/product/context/alert/alertContext'

const InvoiceView = (props) => {
    const customCommonContext = useContext(CustomCommonContext)
    const alertContext = useContext(AlertContext)
    const userContext = useContext(UserContext)
    const { allConfigurationVariables } = customCommonContext
    const { getAllBiddingUsers, all_bidding_users } = userContext
    const { setAlert } = alertContext
    const { t } = useTranslation()
    const invoiceContext = useContext(InvoiceContext)
    const {
        single_invoice,
        getSingleInvoice,
        all_invoices,
        updateInvoice,
        changeProductStatus,
        responseStatus: editResponseStatus,
    } = invoiceContext

    const [viewContent, setViewContent] = React.useState(null)
    const [print, setPrint] = useState(false)
    const [intialPickedupLoad, setIntialPickedupLoad] = useState(false)
    const [biddingUsers, setBiddingUsers] = useState([])
    const [editWinner, setEditWinner] = useState(false)

    const data = props.data
    const toggleFullScreenPopup = props.function

    const formik = useFormik({
        initialValues: {
            cart_id: 0,
        },
        validateOnBlur: false,
    })

    // temp lang Manager
    const cap = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }

    useEffect(() => {
        resetFrom()
    }, [props])

    useEffect(() => {
        if (data.status === 'edit' || data.status === 'view') {
            formik.values.invoice_id = data.id
            formik.values.user_id = data.sid
            if (data.action === 'cancel') {
                formik.values.filters = {
                    active: {
                        type: 'in',
                        field: 'b.active',
                        value: 2,
                    },
                }
            } else {
                formik.values.filters = {
                    active: {
                        type: 'notin',
                        field: 'b.active',
                        value: 2,
                    },
                }
            }
            getSingleInvoice(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])
    let [invoiceDetails, setInvoiceDetails] = useState({
        invoiceProducts: [],
        cartValues: {},
        appointmentDetail: {},
        locationDetail: {},
        invoiceTransactions: [],
        invoiceUser: [],
        invoiceAddress: [],
    })
    function calculateBasePrice(totalPrice, vatRate = 21) {
        // Convert VAT rate percentage to decimal and add 1
        const vatMultiplier = 1 + vatRate / 100
        // Calculate base price by dividing total price by the VAT multiplier
        const basePrice = totalPrice / vatMultiplier
        return basePrice
    }

    function calculateVAT(totalPrice, vatRate = 21) {
        // Calculate base price using the same function as before
        const basePrice = calculateBasePrice(totalPrice, vatRate)
        // VAT Amount is the difference between total price and base price
        const vatAmount = totalPrice - basePrice
        return vatAmount
    }
    const activeBidders = useFormik({
        initialValues: {
            order: '',
            orderby: '',
            project_id: '',
            filters: {
                action: {
                    value: 'active',
                    type: 'in',
                    field: 'u.status',
                },
                admin: {
                    value: '0',
                    type: 'in',
                    field: 'u.admin',
                },
            },
        },
    })

    useEffect(() => {
        console.log(single_invoice, 'inoooo')
        if (single_invoice && (data.status === 'edit' || data.status === 'view')) {
            console.log(single_invoice, 'dinnn')
            activeBidders.values.project_id = single_invoice.invoicerecords[0].project_id
            getAllBiddingUsers(activeBidders.values)
            setInvoiceDetails({
                invoiceProducts: single_invoice.invoicerecords.length
                    ? single_invoice.invoicerecords
                    : [],
                cartValues: single_invoice.cartvalues,
                appointmentDetail: single_invoice.appointmentrecord,
                locationDetail: single_invoice.locationrecord,
                invoiceTransactions: single_invoice.transactionrecords,
                invoiceUser: single_invoice.invoiceuserrecord,
                invoiceAddress: single_invoice.invoiceaddressrecord,
            })
            formikCheckbox.values.picked_up = single_invoice.invoicerecords[0].delivered
                ? true
                : false
        }
    }, [single_invoice])

    const editValidationArray = Yup.object({
        changed_user_id: Yup.string().required('Required'),
    })

    const editFormik = useFormik({
        initialValues: {
            user_id: 0,
            changed_user_id: '',
            invoice_id: '',
            per_total: 0,
            product_type: 'live',
        },
        validateOnBlur: false,
        validationSchema: editValidationArray,
        onSubmit: (values) => {
            updateInvoice(values)
        },
    })

    useEffect(() => {
        if (invoiceDetails) {
            if (invoiceDetails.invoiceProducts.length) {
                editFormik.setValues({
                    per_total: invoiceDetails.cartValues.per_total,
                    invoice_id: invoiceDetails.cartValues.common_invoice,
                    user_id: invoiceDetails.invoiceProducts[0].user_id,
                    product_type: 'live',
                })
            }
        }
    }, [invoiceDetails])

    useEffect(() => {
        if (all_bidding_users) {
            if (all_bidding_users.records.length) {
                let biddingUsers = all_bidding_users.records.map((subcat) => {
                    let subCategoryChanged = {}
                    subCategoryChanged.show = `${subcat.users_email}`
                    subCategoryChanged.value = subcat.users_id
                    return subCategoryChanged
                })
                setBiddingUsers(biddingUsers)
            }
        }
    }, [all_bidding_users])

    useEffect(() => {
        if (editResponseStatus) {
            if (
                editResponseStatus.status === 'success' &&
                editResponseStatus.from === 'invoiceupdate'
            ) {
                if (editWinner) {
                    setAlert(editResponseStatus.message, editResponseStatus.status)
                    formik.values.user_id = editFormik.values.changed_user_id
                    setEditWinner(false)
                }
                getSingleInvoice(formik.values)
            }
        }
    }, [editResponseStatus])

    useEffect(() => {
        setInvoiceDetails({
            invoiceProducts: [],
            cartValues: {},
            appointmentDetail: {},
            locationDetail: {},
            invoiceTransactions: [],
            invoiceUser: [],
            invoiceAddress: [],
        })
        return () => {
            setInvoiceDetails({
                invoiceProducts: [],
                cartValues: {},
                appointmentDetail: {},
                locationDetail: {},
                invoiceTransactions: [],
                invoiceUser: [],
                invoiceAddress: [],
            })
        }
    }, [])

    const buyers = [
        {
            label: t('change_winner'),
            placeholder: 'Select winner',
            type: 'autocomplete',
            class: 'col-12',
            options: biddingUsers,
            defaultOptionValue: 'Select winner',
            name: 'changed_user_id',
            size: 'small',
            formik: editFormik,
        },
    ]
    const resetFrom = () => {
        setViewContent(null)
    }

    const ref = React.createRef()
    const options = {}

    const generatePDF = (toPdf) => {
        setPrint(true)
        toPdf()
    }
    const completedPDF = () => {
        setPrint(false)
    }

    const formikCheckbox = useFormik({
        initialValues: {
            picked_up: false,
        },
        onSubmit: (values) => {
            console.log('handle checked submit ', values)
        },
    })

    const mapDataOptions = {
        formik: formikCheckbox,
        data: [
            {
                label: 'Mark Item as Picked Up',
                type: 'checkbox',
                class: 'col-12',
                name: 'picked_up',
            },
        ],
    }

    const handleClose = () => {
        setEditWinner(false)
    }

    const closeFunction = () => {
        toggleFullScreenPopup(false)
        props.closeFunction()
    }

    // useEffect(() => {
    //     if (data.id) {
    //         let payload = {
    //             action: formikCheckbox.values.picked_up ? 'delivered' : 'undelivered',
    //             project_id: [invoiceDetails.invoiceProducts[0]?.id],
    //         }
    //         // if (intialPickedupLoad && payload.project_id[0]) {
    //         //     changeProductStatus(payload)
    //         // }

    //         setIntialPickedupLoad(true)
    //     }
    // }, [formikCheckbox.values.picked_up, data.id])

    return (
        <FullScreenPopup
            modaltitle={data.status === 'view' ? t('view_document') : t('edit_document')}
            open={data.popup}
            handleClose={() => closeFunction()}
        >
            <div className="container-lg productInvoice">
                {/* <h3 className="invoiceTitle">{t('tax_invoice')}</h3> */}
                <div className="d-flex justify-content-end align-items-center mb-2 noPrint">
                    {/* <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs">
                        <Link to="/dashboard/won">Dashboard</Link>
                        <Typography color="textPrimary">Invoice</Typography>
                    </Breadcrumbs> */}
                    <Button id="invoice_print" onClick={() => window.print()}>
                        <span className="material-icons">print</span>
                        {t('print')}
                    </Button>
                </div>
                <div
                    className={`invoiceView ${print ? 'invoiceViewPrint' : ''}`}
                    ref={ref}
                    style={{ height: '100%', width: '100%' }}
                >
                    <div className="card-header d-flex justify-content-between align-items-center p-4 bottom-border">
                        <div>
                            <h3>
                                {invoiceDetails.invoiceProducts[0]?.projects_auction
                                    ? 'Detalle informativo de Venta por subasta'
                                    : 'Detalle informativo de Venta por subasta'}
                            </h3>
                            <a className="pt-2 d-inline-block" data-abc="true">
                                <img
                                    src={LOGO}
                                    className="invLogo"
                                    alt={SITE_NAME}
                                    style={{ width: '160px' }}
                                />
                            </a>
                        </div>
                        <div className="float-right invDate invDateCust">
                            <h5 className="mb-0">
                                {t('document_number')} #{invoiceDetails.cartValues.common_invoice}{' '}
                            </h5>
                            <h5 className="mb-0">
                                {t('date')}:{' '}
                                {invoiceDetails.invoiceProducts[0]?.active === 2
                                    ? dateTimeFormatFunction(
                                          invoiceDetails.invoiceProducts[0]?.returned_date,
                                      )
                                    : dateTimeFormatFunction(
                                          invoiceDetails.cartValues.cart_paiddate,
                                      )}
                            </h5>

                            {/* <h5 className="mb-0">
                                <span className="font-italic font-weight-bold ">
                                    {
                                        invoiceDetails.invoiceProducts[0]?.active === 2 ? (
                                            <>
                                                {t('status')}: {t('cancelled')}{' '}
                                            </>
                                        ) : invoiceDetails.cartValues.cart_paid ? (
                                            <>
                                                {t('status')}: {t('paid')}{' '}
                                            </>
                                        ) : (
                                            <p className="invoiceUnpaid">
                                                {' '}
                                                Contactar con contrapartida lo antes posible
                                            </p>
                                        )
                                        // : t('unpaid')
                                    }
                                </span>
                            </h5> */}
                            <h5 className="mb-0">
                                <span>
                                    {
                                        invoiceDetails.invoiceProducts[0]?.active === 2 ? (
                                            <>
                                                <>
                                                    {' '}
                                                    {t('status')}:{''}{' '}
                                                </>
                                                <span className="font-italic font-weight-bold ">
                                                    {' '}
                                                    {t('cancelled')}{' '}
                                                </span>
                                            </>
                                        ) : invoiceDetails.cartValues.cart_paid ? (
                                            <>
                                                <>
                                                    {' '}
                                                    {t('status')}: {''}{' '}
                                                </>
                                                <span className="font-italic font-weight-bold ">
                                                    {t('paid')}{' '}
                                                </span>
                                            </>
                                        ) : (
                                            <p className="invoiceUnpaid  font-italic font-weight-bold">
                                                {' '}
                                                Contactar con contrapartida lo antes posible
                                            </p>
                                        )
                                        // : t('unpaid')
                                    }
                                </span>
                            </h5>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row mb-4 printCustSeller">
                            <div className="col-sm-6 invAddr">
                                <h5 className="mb-3">{t('from')}:</h5>
                                <h3 className="text-dark mb-1">{'Subastas Pro'}</h3>

                                {/* <div>{invoiceDetails.locationDetail.address}</div>
                            <div>
                                {invoiceDetails.locationDetail.city},{' '}
                                {invoiceDetails.locationDetail.state}{' '}
                                {invoiceDetails.locationDetail.zipCode}, United Arab Emirates
                            </div> */}

                                <div className="textChangeClo">
                                    {t('phone')} ({cap('international')}): +1 (989) 898-9898
                                </div>
                            </div>

                            {invoiceDetails.invoiceUser?.length ? (
                                <div className="col-sm-6 invAddr text-right">
                                    <h5 className="mb-3">{t('Para')}:</h5>
                                    <h3 className="text-dark mb-1">
                                        {invoiceDetails.invoiceUser[0].first_name}{' '}
                                        {invoiceDetails.invoiceUser[0].last_name}
                                    </h3>
                                    <div className="textChangeClo">
                                        <div>{invoiceDetails?.invoiceUser[0]?.address1}</div>
                                        <div>{invoiceDetails?.invoiceUser[0]?.address2}</div>
                                        {invoiceDetails.invoiceUser[0].city &&
                                            invoiceDetails.invoiceUser[0].state &&
                                            invoiceDetails.invoiceUser[0].country && (
                                                <div>
                                                    {invoiceDetails.invoiceUser[0].city},{' '}
                                                    {invoiceDetails.invoiceUser[0].state},{' '}
                                                    {invoiceDetails.invoiceUser[0].country} -{' '}
                                                    {invoiceDetails.invoiceUser[0].zip}
                                                </div>
                                            )}
                                    </div>

                                    <div className="textChnage">
                                        <div> {t('email')}:</div>{' '}
                                        <span>{invoiceDetails.invoiceUser[0].email}</span>
                                    </div>
                                    <div className="textChnage">
                                        <div> {t('phone')}:</div>{' '}
                                        <span>{invoiceDetails.invoiceUser[0].phone}</span>
                                    </div>
                                </div>
                            ) : null}
                            <div className="float-left col-sm-6 invDate invDateCus">
                                <h5 className="mb-0">
                                    <b>{t('document_number')} #</b>
                                    {invoiceDetails.cartValues.common_invoice}{' '}
                                </h5>
                                <h5 className="mb-0">
                                    <b>{t('date')}:</b>{' '}
                                    {invoiceDetails.invoiceProducts[0]?.active === 2
                                        ? dateTimeFormatFunction(
                                              invoiceDetails.invoiceProducts[0]?.returned_date,
                                          )
                                        : dateTimeFormatFunction(
                                              invoiceDetails.cartValues.cart_paiddate,
                                          )}
                                </h5>
                                {/* <h5 className="mb-0">
                                    <b> {t('status')}:</b>{' '}
                                    <span className="font-italic font-weight-bold">
                                        {
                                            invoiceDetails.invoiceProducts[0]?.active === 2
                                                ? t('cancelled')
                                                : invoiceDetails.cartValues.cart_paid
                                                ? t('paid')
                                                : 'Contactar con contrapartida lo antes posible'
                                          
                                        }
                                    </span>
                                </h5> */}
                                <h5 className="mb-0">
                                    <span>
                                        {
                                            invoiceDetails.invoiceProducts[0]?.active === 2 ? (
                                                <>
                                                    <>
                                                        {' '}
                                                        {t('status')}:{''}{' '}
                                                    </>
                                                    <span className="font-italic font-weight-bold ">
                                                        {' '}
                                                        {t('cancelled')}{' '}
                                                    </span>
                                                </>
                                            ) : invoiceDetails.cartValues.cart_paid ? (
                                                <>
                                                    <>
                                                        {' '}
                                                        {t('status')}: {''}
                                                    </>
                                                    <span className="font-italic font-weight-bold ">
                                                        {t('paid')}{' '}
                                                    </span>
                                                </>
                                            ) : (
                                                <p className="invoiceUnpaid  font-italic font-weight-bold">
                                                    {' '}
                                                    Contactar con contrapartida lo antes posible
                                                </p>
                                            )
                                            // : t('unpaid')
                                        }
                                    </span>
                                </h5>
                            </div>
                        </div>
                        {/* {invoiceDetails.cartValues.cart_payment ? (
                        <>
                            <h4 className="invoiceSubTitle">
                                Payment Type:{' '}
                                {invoiceDetails.cartValues.cart_payment === 'online' ? (
                                    <>{t('online_payment')}</>
                                ) : invoiceDetails.cartValues.cart_payment === 'offline' ? (
                                    <>{t('offline_payment')}</>
                                ) : invoiceDetails.cartValues.cart_payment === 'deposit' ? (
                                    <>{t('deposit')}</>
                                ) : null}
                            </h4>
                        </>
                    ) : null} */}

                        {invoiceDetails.appointmentDetail ? (
                            <>
                                {/* <h4 className="invoiceSubTitle">
                                    {t('appointment_scheduled_at')}:{' '}
                                    {dateTimeFormatFunction(
                                        invoiceDetails.appointmentDetail.appointmentTime,
                                    )}
                                </h4> */}
                            </>
                        ) : null}
                        {invoiceDetails.invoiceProducts[0]?.active === 2 ? (
                            <>
                                <h4 className="invoiceSubTitle text-danger">
                                    {'Invoice Cancelled: '}
                                    {invoiceDetails.invoiceProducts[0]?.notes}
                                </h4>
                            </>
                        ) : null}
                        {invoiceDetails.invoiceTransactions.length ? (
                            <>
                                <h4 className="invoiceSubTitle">{t('transaction_details')}</h4>
                                {/* <div className="row">{mapData(mapDataOptions)}</div> */}
                                <div className="transDet d-flex justify-content-between flex-wrap">
                                    <>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col">S.No</th> */}
                                                    {/* <th scope="col">{t('transaction_id')}</th> */}
                                                    <th scope="col">{t('paid_on')}</th>
                                                    {/* <th scope="col">{t('method')}</th> */}
                                                    <th scope="col">{t('amount')}</th>
                                                    <th scope="col">{t('status')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceDetails.invoiceTransactions.map(
                                                    (data, index) => (
                                                        <tr key={index}>
                                                            {/* <td data-title={t('transaction_id')}>
                                                                {data.trans_id
                                                                    ? `# ${data.trans_id}`
                                                                    : '-'}
                                                            </td> */}
                                                            <td data-title={t('paid_on')}>
                                                                {dateTimeFormatFunction(
                                                                    data.created_at,
                                                                )}
                                                            </td>

                                                            <td data-title={t('amount')}>
                                                                {currencyFormat(data.amount)}
                                                            </td>
                                                            <td
                                                                id="invoice_status"
                                                                data-title={t('status')}
                                                                style={{
                                                                    color:
                                                                        data.status === 'success'
                                                                            ? 'green'
                                                                            : 'red',
                                                                }}
                                                            >
                                                                {capitalize(t(data.status))}
                                                            </td>
                                                        </tr>
                                                    ),
                                                )}
                                            </tbody>
                                        </table>
                                    </>
                                </div>
                            </>
                        ) : null}
                        <h4 className="invoiceSubTitle mt-5">{t('product_details')}</h4>

                        <div className="table-responsive">
                            {invoiceDetails.invoiceProducts.length ? (
                                <div className="cartProducts">
                                    <div>
                                        {invoiceDetails.invoiceProducts.map((data, index) => (
                                            <CartItem data={data} from="invoice" />
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-clear nellisInvTot">
                                        <tbody>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">{t('items')}</span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {invoiceDetails.invoiceProducts.length}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {t('sub_total')}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {invoiceDetails?.invoiceProducts[0]
                                                            ?.custom_projects_iva_percent
                                                            ? currencyFormat(
                                                                  calculateBasePrice(
                                                                      invoiceDetails.cartValues
                                                                          .per_total,
                                                                      21,
                                                                  ),
                                                              )
                                                            : currencyFormat(
                                                                  invoiceDetails.cartValues
                                                                      .per_total,
                                                              )}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {'IVA'}
                                                        {invoiceDetails.invoiceProducts.length ? (
                                                            <>
                                                                {' '}
                                                                (
                                                                {invoiceDetails?.invoiceProducts[0]
                                                                    ?.custom_projects_iva_percent
                                                                    ? invoiceDetails
                                                                          ?.invoiceProducts[0]
                                                                          ?.custom_projects_iva_percent
                                                                    : 0}
                                                                % )
                                                            </>
                                                        ) : null}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {invoiceDetails?.invoiceProducts[0]
                                                            ?.custom_projects_iva_percent
                                                            ? currencyFormat(
                                                                  calculateVAT(
                                                                      invoiceDetails.cartValues
                                                                          .per_total,
                                                                      21,
                                                                  ),
                                                              )
                                                            : 'REBU'}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {t('total_amount')}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.per_total,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                            {invoiceDetails.cartValues.total_deposit ? (
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('bid_deposit')}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            -{' '}
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues
                                                                    .total_deposit,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ) : null}

                                            {invoiceDetails.cartValues.total_paid ? (
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('total_paid')}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues
                                                                    .total_paid,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ) : null}

                                            {invoiceDetails.cartValues.total_due_amount ? (
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('pending_amount')}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues.per_total,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 className="thankText">{t('thank_you')}</h4>
            </div>
            <CustomDialog
                open={editWinner}
                function={() => setEditWinner(false)}
                title={t('edit_winner')}
                disableBackdropClick
                handleClose={handleClose}
            >
                <div className="row wnrDialog mb-3">{Object.values(mapData(buyers))}</div>
                <PrimaryButton
                    onClick={editFormik.handleSubmit}
                    type="button"
                    label={t('submit')}
                />
            </CustomDialog>
        </FullScreenPopup>
    )
}

export default InvoiceView
