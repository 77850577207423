import React, { useState } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { ListItem } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import CustomDialog from '../../../../product/components/organisms/Dialog'
import { Tooltip, IconButton } from '@material-ui/core'
import './RadioBox.css'

export const GreenRadio = withStyles({
    root: {
        color: 'var(--primColor)',
        '&$checked': {
            color: 'var(--primColor)',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />)

function RadioBox(props) {
    const [toggleDialog, setToggleDialog] = useState(false)
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }
    return (
        <div className="RadioBox">
            <h6 className="radioTitle py-2">
                {props.title}{' '}
                {props?.lableInfo ? (
                    <Tooltip title={props?.tooltiptitle}>
                        <IconButton>
                            <span
                                className="material-icons"
                                onClick={() => {
                                    if (props?.contents) {
                                        setToggleDialog(true)
                                    }
                                }}
                            >
                                info
                            </span>
                        </IconButton>
                    </Tooltip>
                ) : null}
            </h6>
            <div>
                <RadioGroup
                    aria-label={props.name}
                    name={props.name}
                    value={props.int === 1 ? parseInt(props.value) : props.value}
                    onChange={props.onChange}
                >
                    {props.items.map((d, i) => (
                        <>
                            <FormControlLabel
                                key={i}
                                className={
                                    props.value && props.value == d.id ? 'checked' : 'unChecked'
                                }
                                value={props.int === 1 ? parseInt(d.id) : d.id}
                                control={
                                    <GreenRadio
                                        disabled={props.disabled ? props.disabled : false}
                                    />
                                }
                                label={d.description}
                            />
                        </>
                    ))}
                </RadioGroup>
                <FormHelperText>{props.error}</FormHelperText>
            </div>

            <CustomDialog
                title={
                    <div className="bidConfirmTitle">
                        {props?.tooltiptitle}
                        <div className="ssmHead d-flex justify-content-center">
                            <button
                                type="button"
                                className="close"
                                id="cancel"
                                onClick={() => setToggleDialog(false)}
                            >
                                <span className="material-icons">clear</span>
                            </button>
                        </div>
                    </div>
                }
                open={toggleDialog}
                function={changeDialogStatus}
                className="bidConfirmationCstm"
            >
                <div className="">
                    {props?.contents
                        ? props?.contents
                              .replace(/\$enter/g, '\n')
                              .split('\n')
                              .map((line, index) => <p key={index}>{line}</p>)
                        : null}
                </div>
            </CustomDialog>
        </div>
    )
}

export default RadioBox
