import React, { useContext, useEffect, useState } from 'react'
import '../Subscription/Subscription.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData, handleRedirectInternal } from '../../product/common/components'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Switch from '@material-ui/core/Switch'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import AlertContext from '../../product/context/alert/alertContext'
import SkyflowCardDetails from '../../product/components/organisms/SkyflowCardDetails/index'
// import SkyflowCardDetails from '../../custom/components/organisms/SkyflowCardDetails'
import BankDetails from '../../custom/components/organisms/BankDetails'
import { currencyFormat, capitalize, dateFormatFunction } from '../../custom/common/components'
import PayContext from '../../product/context/payment/payContext'
import CommonContext from '../../custom/context/common/commonContext'
import AuthContext from '../../product/context/auth/authContext'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import StripeCardContext from '../../product/context/stripe/card/cardContext'
import StripeBankContext from '../../product/context/stripe/bank/bankContext'
import StripeCustomerContext from '../../product/context/stripe/customer/customerContext'
import MyPaymentComponent from '../../product/components/species/components/user/MyPaymentComponent'
import { useTranslation } from 'react-i18next'
import MembershipContext from '../../product/context/membership/membershipContext'

import UserContext from '../../product/context/user/userContext'
import { Button, Dialog, IconButton, ListItem } from '@material-ui/core'
import CustomDialog from '@/custom/components/organisms/CustomDialog'
import DashboardLayout from '../Dashboard/DashboardLayout'
import { CommonSEO } from '@/utils/SEO'

const creditCardYearOptions = []
let date = new Date()
let currentYear = date.getFullYear()
let upToYear = parseInt(currentYear) + 25
for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
    creditCardYearOptions.push({
        value: year,
        show: year,
    })
}
const creditCardMonthOptions = []
for (let month = parseInt(1); month <= parseInt(12); month++) {
    let monthValue = ('0' + month).slice(-2)
    creditCardMonthOptions.push({
        value: monthValue,
        show: monthValue,
    })
}
const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: 'red',
        position: 'absolute',
        left: '50%',
        marginTop: -50,
        marginLeft: -12,
    },
}))
export default function MySubscriptionPlan(props) {
    const classes = useStyles()
    const history = useHistory()
    const { setAlert } = useContext(AlertContext)
    const payContext = useContext(PayContext)
    const membershipContext = useContext(MembershipContext)
    const { t } = useTranslation()
    const authContext = useContext(AuthContext)
    const { loadUser, user, isAuthenticated } = authContext

    const { payCartStripe, payCustomCartStripe, responseStatus: responseStatusPayment } = payContext

    const { freeTrialActivation, responseStatus: responseStatusMembership } = membershipContext

    const { allBiddingPlan, membership, setCurrentMembership, setLoginModal } =
        useContext(CommonContext)

    // const { cashFreeMembershipPayment } = useContext(PayContext)
    const [cards, setCards] = useState([])
    const [banks, setBanks] = useState([])
    const [selectedCard, setSelectedCard] = useState(false)

    const {
        skyflow_cards,
        getSkyflowCredit,
        clearResponse: clearResponseCard,
    } = useContext(UserContext)

    const {
        get_all_bank_details,
        getAllStripeBanks,
        responseStatus: responseStatusBank,
    } = useContext(StripeBankContext)

    const {
        customer_details,
        createStripeCustomer,
        getStripeCustomer,
        responseStatus: customerResponseStatus,
    } = useContext(StripeCustomerContext)

    const {
        get_all_card_details,
        createStripeCard,
        createCustomStripeCard,
        createStripeCardWithCustomer,
        getAllStripeCards,
        deleteStripeCard,
        updateStripeCard,
        getAllCustomStripeCards,
        deleteCustomStripeCard,
        updateCustomStripeCard,
        responseStatus: responseStatusCard,
        clearResponse,
    } = useContext(StripeCardContext)

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }

    const [manageBank, setManageBank] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenBankPopup = (popup, status, data) => {
        setManageBank({ popup, status, data })
    }

    const [paymentLoading, setPaymentLoading] = useState(false)
    const [subPayPopup, setSubPayPopup] = useState(false)
    const [freeTrialPopup, setFreeTrialPopup] = useState(false)

    const validationFreeTrial = Yup.object({
        planName: Yup.string().required(t('Required!')),
    })

    const validationPayment = Yup.object({
        payment_method: Yup.string().required(t('Required!')),
        planName: Yup.string().required(t('Required!')),
        period: Yup.string().required(t('Required!')),
    })

    const freeTrialFormik = useFormik({
        initialValues: {
            planName: '',
            period: 'free',
        },
        validateOnBlur: false,
        validationSchema: validationFreeTrial,
        onSubmit: (values) => {
            // setLoading(true)
            freeTrialFormik.setSubmitting(true)
            freeTrialActivation(values)
        },
    })

    const cardFormik = useFormik({
        initialValues: {
            cards: '',
            payment_method: 'card',
            no_of_cards: 'existing',
            pay_type: 'membership_payment',
            amount: 0,
            planName: '',
            period: 'monthly',
        },
        validateOnBlur: false,
        validationSchema: validationPayment,
        onSubmit: (values) => {
            // setLoading(true)
            cardFormik.setSubmitting(true)
            let formData = {
                payment_object: {
                    planName: values.planName,
                },
                period: values.period,
                paymentInfo: {
                    source: selectedCard.id,
                    customer: user.stripe_id,
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                },
                payment_method: 'online',
                currency: 'EUR',
                payment_type: selectedCard.card_last4 ? 'card' : 'bank',
                pay_type: values.pay_type,
                amount: 1,
                customCartFields: props.customCartFields || null,
                admin_fee: props.transactionFee ? cartValues.processing_fees : null,
                admin_fee_percent: props.transactionFee ? cartValues.processing_percent : null,
                project: 'subastaspro',
                additional_buynow_data: { shipping_service_type: '' },
            }
            // let formData = {
            //     payment_object: {
            //         planName: values.planName,
            //     },
            //     paymentInfo: {
            //         customer: user.stripe_id,
            //     },
            //     skyflow_id: selectedCard.id,
            //     payment_method: 'online',
            //     payment_type: selectedCard.card_last4 ? 'card' : 'bank',
            //     pay_type: values.pay_type,
            //     amount: values.amount,
            //     period: values.period,
            //     currency: 'USD',
            //     account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            //     project: 'bintimes',
            // }
            console.log(formData, 'formData')
            // payCartStripe(formData)
            if (selectedCard.id) {
                payCustomCartStripe(formData)
            } else {
                setAlert('Please select/add a card to proceed', 'error')
            }
        },
    })

    const skipSubscriptionStep = () => {
        setAlert(
            'Thank you for registering! Please verify your email before logging in.',
            'success',
        )
        handleRedirectInternal(history, 'login')
    }

    const selectFreeTrialPlan = (data) => {
        if (data) {
            setFreeTrialPopup(true)
            freeTrialFormik.setFieldValue('planName', data.type)
        }
    }

    const selectSubscriptionPlan = (data) => {
        if (isAuthenticated) {
            if (user) {
                if (data) {
                    setSubPayPopup(true)
                    cardFormik.setFieldValue('planName', data.type)
                    if (cardFormik.values.period === 'monthly') {
                        cardFormik.setFieldValue('amt', data.monthly_deposit_amount)
                    } else {
                        cardFormik.setFieldValue('amt', data.yearly_deposit_amount)
                    }
                }
            }
        } else {
            handleRedirectInternal(history, 'registration')
        }
    }

    useEffect(() => {
        if (props.from === 'bidding') {
            localStorage.setItem(
                'redirect',
                window.location.href.slice(window.location.origin.length + 1),
            )
        }
    }, [props])
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const handleChange = (event) => {
        if (event.target.checked) {
            cardFormik.setFieldValue('period', 'yearly')
        } else {
            cardFormik.setFieldValue('period', 'monthly')
        }
    }
    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                if (user.membership) {
                    if (allBiddingPlan && allBiddingPlan.length) {
                        let activePlan = allBiddingPlan.filter(
                            (value) => value.type === user.membership,
                        )
                        if (activePlan.length) {
                            setCurrentMembership(activePlan[0])
                        } else {
                            setCurrentMembership('')
                        }
                    }
                } else {
                    setCurrentMembership('')
                }
            }
        }
    }, [isAuthenticated, user, allBiddingPlan])

    const getStripeBanks = () => {
        getAllStripeBanks({
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user ? user.stripe_id : '',
            object: 'bank_account',
        })
        toggleFullScreenBankPopup(false, 'new', null)
    }

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         if (user) {
    //             if (user.id) {
    //                 if (user.stripe_id) {
    //                     getSkyflowCredit()
    //                     getStripeBanks()
    //                 }
    //         }
    //     }
    // }, [user, isAuthenticated])

    useEffect(() => {
        if (user && user.membership_period === 'yearly') {
            cardFormik.setFieldValue('period', 'yearly')
        }
    }, [user, membership])

    useEffect(() => {
        if (skyflow_cards) {
            setCards(skyflow_cards)
        }
        return () => {
            setCards([])
        }
    }, [skyflow_cards])

    useEffect(() => {
        if (get_all_bank_details && get_all_bank_details.records.length) {
            setBanks(get_all_bank_details.records.filter((inner) => inner.status !== 'new'))
        }
        return () => {
            setBanks([])
        }
    }, [get_all_bank_details])

    const handleSelect = (object) => {
        setSelectedCard(object)
        if (object.card_last4) {
            cardFormik.setFieldValue('payment_method', 'card')
            cardFormik.setFieldValue('cards', object.id)
        } else {
            cardFormik.setFieldValue('payment_method', 'bank')
            cardFormik.setFieldValue('cards', object.id)
        }
    }

    const submitButton = (type, data) => {
        if (isAuthenticated) {
            if (type === 'active') {
                return <PrimaryButton label={t('active_plan')} disabled />
            } else if (type === 'freetrial') {
                if (cards.length) {
                    return (
                        <SecondaryButton
                            btnSize="small"
                            label="Free Trial"
                            onClick={() => selectFreeTrialPlan(data)}
                            id="free_trail"
                        />
                    )
                } else {
                    return (
                        <SecondaryButton
                            btnSize="small"
                            label="Free Trial"
                            id="add_card_trail"
                            onClick={() => {
                                setAlert('Need to add card for activating trial', 'error')
                                handleRedirectInternal(history, 'seller/payments/saved_cards')
                                // selectSubscriptionPlan(data)
                                toggleFullScreenCardPopup(true, 'new', null)
                            }}
                        />
                    )
                }
            } else if (type === 'upgrade') {
                return (
                    <PrimaryButton
                        id="upgrade"
                        label={t('upgrade')}
                        onClick={() => selectSubscriptionPlan(data)}
                    />
                )
            } else if (type === 'started') {
                return (
                    <PrimaryButton
                        id="get_started"
                        label={t('Get Started')}
                        onClick={() => selectSubscriptionPlan(data)}
                    />
                )
            } else if (type === 'active_started') {
                return (
                    <PrimaryButton
                        id="trail_active"
                        label="Trial Active"
                        onClick={() => selectSubscriptionPlan(data)}
                    />
                )
            } else if (type === 'contact') {
                return (
                    <PrimaryButton
                        label={t('contact_us')}
                        onClick={() => handleRedirectInternal(history, 'contactus')}
                        id="contact_us"
                    />
                )
            }
        } else {
            return (
                <PrimaryButton
                    id="get_started"
                    label={t('Get Started')}
                    onClick={() => setLoginModal(true)}
                />
            )
        }
    }

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'CUSTOM_PAYMENT') {
                if (responseStatusPayment.status === 'success') {
                    loadUser()
                    setSubPayPopup(false)
                }
            }
            // setIsLoading(false)
        }
    }, [responseStatusPayment])

    useEffect(() => {
        if (responseStatusMembership) {
            if (responseStatusMembership.from === 'freeTrialActivation') {
                if (responseStatusMembership.status === 'success') {
                    loadUser()
                    setFreeTrialPopup(false)
                }
            }
        }
    }, [responseStatusMembership])

    useEffect(() => {
        if (get_all_card_details && get_all_card_details.records.length) {
            setCards(get_all_card_details.records)
        } else {
            setCards([])
        }
    }, [get_all_card_details])

    useEffect(() => {
        if (responseStatusBank) {
            if (
                responseStatusBank.from === 'bank_source_create' ||
                responseStatusBank.from === 'bank_source_update' ||
                responseStatusBank.from === 'bank_source_delete'
            ) {
                if (responseStatusBank.status === 'success') {
                    getStripeBanks()
                }
            }
            // setIsLoading(false)
        }
    }, [responseStatusBank])

    return (
        <>
            <CommonSEO
                title="Contrataciones y paquetes"
                description="Contrataciones y paquetes en Subastas Pro"
            />
            <div className="subsWrapper customContainer">
                <div>
                    <h2 className="subsTitle text-left">{t('subscription_plans')}</h2>
                    <div className="tmSwtBox d-flex align-items-center justify-content-start">
                        {/* {user && user.membership_period === 'yearly' ? (
                            <></>
                        ) : (
                            <>
                                <label
                                    className={`m-0 mr-2 ${
                                        cardFormik.values.period === 'monthly' ? 'colorPrimary' : ''
                                    }`}
                                >
                                    {t('monthly')}
                                </label>
                                <Switch
                                    checked={cardFormik.values.period === 'yearly' ? true : false}
                                    onChange={handleChange}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    id="switch"
                                />
                                <label
                                    className={`m-0 ml-2 ${
                                        cardFormik.values.period === 'yearly' ? 'colorPrimary' : ''
                                    }`}
                                >
                                    {t('yearly')}
                                </label>{' '}
                            </>
                        )} */}
                    </div>
                    <div className="SubTablesCnt">
                        <div className="SubsTableWrpr">
                            <div className="fstColmn colWrpr">
                                <div></div>
                                <div className="tblHdrVw">
                                    <h3>{t('features')}</h3>
                                </div>
                                <div>IVA(21%) no incluida</div>
                                <div>Nº de ventas directas</div>
                                <div>Nº de subastas</div>
                                {/* <div>No of {t('direct_sales')}</div>
                            <div>No of {t('auctions')}</div> */}
                                {/* <div>Auction templates/cloning</div>
                        <div>Pre-bid functionality</div>
                        <div>Proxy bidding available</div>
                        <div>Duplicate bids allowed</div>
                        <div>Mulitple shifts or location grouping</div>
                        <div>Sniping prevention with Automated Auction extensions</div>
                        <div>Success Manager</div>
                        <div>Onboarding*</div> */}
                            </div>

                            {allBiddingPlan?.map((data, index) => (
                                // eslint-disable-next-line react/jsx-key

                                <div
                                    className={`plnColmn colWrpr ${
                                        data.type == membership?.type ? 'active' : ''
                                    }`}
                                >
                                    <div className="ofera">{t(data.plan_type)}</div>
                                    <div className="tblHdrVw">
                                        <div className="plnTlDiv d-block text-center">
                                            {/* {data.type == membership?.type &&
                                                user.membership_period ==
                                                    cardFormik.values.period ? (
                                                    <p className="activeTag">{t('active')}</p>
                                                ) : null} */}

                                            <h2>{data.plan_name}</h2>
                                            {data.type === 'enterprise' && (
                                                <Link className="cntLnk" to="/contactus">
                                                    Contact Us
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mesName">
                                        {data.type !== 'enterprise' && (
                                            <>
                                                {cardFormik.values.period === 'yearly' ? (
                                                    <>
                                                        {currencyFormat(data.yearly_deposit_amount)}
                                                        /{t('year')}
                                                    </>
                                                ) : (
                                                    <>
                                                        {currencyFormat(
                                                            data.monthly_deposit_amount,
                                                        )}{' '}
                                                        / {index == 0 ? '6' : null} {t('month')}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {console.log(data, 'data123')}

                                    <div>
                                        {data.type === 'enterprise' ? (
                                            'Unlimited'
                                        ) : (
                                            <>
                                                {cardFormik.values.period === 'yearly'
                                                    ? data.users_limit_to
                                                    : ` ${
                                                          index == 0
                                                              ? 'illimitado'
                                                              : data.monthly_lots
                                                      } `}
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        {data.type === 'enterprise'
                                            ? 'Unlimited'
                                            : cardFormik.values.period === 'yearly'
                                            ? data.auctions_year
                                            : ` ${
                                                  index == 0 ? 'illimitado' : data.monthly_auction
                                              } `}
                                    </div>
                                    {/* <div>{data.additonal_auction ? data.additonal_auction : 'N/A'}</div> */}
                                    {/* <div>
                                    {data.auction_template ? (
                                            <span className="material-icons-outlined avlBtn">
                                                check_circle
                                            </span>
                                        ) : (
                                            <span className="material-icons-outlined ntAvlBtn">
                                                highlight_off
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        {data.pre_bid ? (
                                            <span className="material-icons-outlined avlBtn">
                                                check_circle
                                            </span>
                                        ) : (
                                            <span className="material-icons-outlined ntAvlBtn">
                                                highlight_off
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        {data.proxy_bid ? (
                                            <span className="material-icons-outlined avlBtn">
                                                check_circle
                                            </span>
                                        ) : (
                                            <span className="material-icons-outlined ntAvlBtn">
                                                highlight_off
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        {data.duplicate_bid ? (
                                            <span className="material-icons-outlined avlBtn">
                                                check_circle
                                            </span>
                                        ) : (
                                            <span className="material-icons-outlined ntAvlBtn">
                                                highlight_off
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                        {data.multishift ? (
                                            <span className="material-icons-outlined avlBtn">
                                                check_circle
                                            </span>
                                        ) : (
                                            <span className="material-icons-outlined ntAvlBtn">
                                                highlight_off
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        {data.extend_auction ? (
                                            <span className="material-icons-outlined avlBtn">
                                                check_circle
                                            </span>
                                        ) : (
                                            <span className="material-icons-outlined ntAvlBtn">
                                                highlight_off
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        {data.success_manager ? (
                                            <span className="material-icons-outlined avlBtn">
                                                check_circle
                                            </span>
                                        ) : (
                                            <span className="material-icons-outlined ntAvlBtn">
                                                highlight_off
                                            </span>
                                        )}
                                    </div> */}
                                    {/* <div>
                                        {data?.type === 'gold'
                                            ? '1 session included'
                                            : data?.type === 'enterprise'
                                            ? 'Included'
                                            : '$395.00/session'}
                                    </div> */}
                                </div>
                            ))}
                        </div>
                        <div className="SubsTableWrpr planTableWrpr">
                            <div className="planCnt">{t('Mi Plan')}</div>
                            {allBiddingPlan?.map((data, index) => (
                                <div className="planCnt">
                                    {data.type != 'free' ? (
                                        membership ? (
                                            <>
                                                {user.membership_period === 'monthly' ? (
                                                    <>
                                                        {console.log(
                                                            '=====>',
                                                            cardFormik.values.period,
                                                        )}
                                                        {cardFormik.values.period === 'monthly' ? (
                                                            <>
                                                                {membership.type == data.type ? (
                                                                    <>
                                                                        {submitButton(
                                                                            'active',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                ) : membership.monthly_deposit_amount <
                                                                  data.monthly_deposit_amount ? (
                                                                    <>
                                                                        {submitButton(
                                                                            'upgrade',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                ) : membership.monthly_deposit_amount >
                                                                  data.monthly_deposit_amount ? (
                                                                    <> </>
                                                                ) : (
                                                                    <>
                                                                        {submitButton(
                                                                            'started',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : cardFormik.values.period ===
                                                          'yearly' ? (
                                                            <>
                                                                {/* {membership.type == data.type ? (
                                                            <>{submitButton('active', data)}</>
                                                        ) : membership.yearly_deposit_amount <
                                                          data.yearly_deposit_amount ? (
                                                            <>{submitButton('upgrade', data)}</>
                                                        ) : membership.yearly_deposit_amount >
                                                          data.yearly_deposit_amount ? (
                                                            <> </>
                                                        ) : ( */}
                                                                <>{submitButton('started', data)}</>
                                                                {/* )} */}
                                                            </>
                                                        ) : null}
                                                    </>
                                                ) : user.membership_period === 'yearly' ? (
                                                    <>
                                                        {cardFormik.values.period === 'yearly' ? (
                                                            <>
                                                                {membership.type == data.type ? (
                                                                    <>
                                                                        {submitButton(
                                                                            'active',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                ) : membership.yearly_deposit_amount <
                                                                  data.yearly_deposit_amount ? (
                                                                    <>
                                                                        {submitButton(
                                                                            'upgrade',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                ) : membership.yearly_deposit_amount >
                                                                  data.yearly_deposit_amount ? (
                                                                    <> </>
                                                                ) : (
                                                                    <>
                                                                        {submitButton(
                                                                            'started',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : cardFormik.values.period ===
                                                          'monthly' ? (
                                                            <> </>
                                                        ) : null}
                                                    </>
                                                ) : user.membership_period === 'free' ? (
                                                    <>
                                                        {cardFormik.values.period === 'yearly' ? (
                                                            <>
                                                                {membership.type === data.type ? (
                                                                    <>
                                                                        {submitButton(
                                                                            'active_started',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                ) : data.type === 'enterprise' ? (
                                                                    <>
                                                                        {submitButton(
                                                                            'contact',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {submitButton(
                                                                            'started',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : cardFormik.values.period ===
                                                          'monthly' ? (
                                                            <>
                                                                {membership.type === data.type ? (
                                                                    <>
                                                                        {submitButton(
                                                                            'active_started',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                ) : data.type === 'enterprise' ? (
                                                                    <>
                                                                        {submitButton(
                                                                            'contact',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {submitButton(
                                                                            'started',
                                                                            data,
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                            </>
                                        ) : (
                                            <>{submitButton('started', data)}</>
                                        )
                                    ) : data.type == 'free' && !isAuthenticated ? (
                                        submitButton('started', data)
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>

                    <FullScreenPopup
                        className={'adTempltPopup subPayPopup'}
                        modaltitle={'Free Trial Confirmation'}
                        open={freeTrialPopup}
                        handleClose={() => setFreeTrialPopup(false)}
                    >
                        <div className="addUserModal mt-4">
                            {freeTrialFormik.values.planName ? (
                                <div
                                    className={`orderReviewBox ${freeTrialFormik.values.planName}`}
                                >
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="lftRvwBox">
                                            <h5>Order Review</h5>
                                            <ul className="orderListli">
                                                <li>
                                                    <span className="planNameWrp">
                                                        <b>
                                                            {`${capitalizeFirstLetter(
                                                                freeTrialFormik.values.planName,
                                                            )} Plan - Free Trial`}
                                                        </b>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="rtImg">
                                            <img src="/assets/images/crown.png" />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {freeTrialFormik.values.planName ? (
                                <>
                                    <div className="mt-4">
                                        <PrimaryButton
                                            onClick={() => freeTrialFormik.handleSubmit()}
                                            label={t('submit')}
                                            type="submit"
                                            disabled={paymentLoading}
                                            id="plan_submit"
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </FullScreenPopup>

                    <CustomDialog
                        maxWidth={'xl'}
                        className={'adTempltPopup subPayPopup upgradeModal'}
                        title={t('subscription_payment')}
                        open={subPayPopup}
                        function={() => setSubPayPopup(false)}
                    >
                        <div className="addUserModal mt-4">
                            {cardFormik.values.planName ? (
                                <div className={`orderReviewBox ${cardFormik.values.planName}`}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="lftRvwBox">
                                            <h5>{t('order_review')}</h5>
                                            <ul className="orderListli">
                                                {membership ? (
                                                    <>
                                                        <li>
                                                            <span>
                                                                <b>
                                                                    {t('existing_plan')} :{' '}
                                                                    {capitalize(membership.type)}{' '}
                                                                    Plan
                                                                </b>
                                                            </span>
                                                            <span>
                                                                {user.membership_period ===
                                                                'monthly' ? (
                                                                    <>
                                                                        {currencyFormat(
                                                                            membership.monthly_deposit_amount,
                                                                        )}
                                                                        /{' '}
                                                                        {user?.membership == 'free'
                                                                            ? 6
                                                                            : ''}
                                                                        {t('month')}
                                                                    </>
                                                                ) : user.membership_period ===
                                                                  'yearly' ? (
                                                                    <>
                                                                        {currencyFormat(
                                                                            membership.yearly_deposit_amount,
                                                                        )}
                                                                        /{t('year')}
                                                                    </>
                                                                ) : user.membership_period ===
                                                                  'free' ? (
                                                                    <>Free Trial</>
                                                                ) : null}
                                                            </span>
                                                        </li>
                                                        <li className="divider"></li>
                                                    </>
                                                ) : null}

                                                <li>
                                                    <span className="planNameWrp">
                                                        <b>
                                                            {`${capitalizeFirstLetter(
                                                                cardFormik.values.planName,
                                                            )} Plan`}
                                                        </b>
                                                    </span>
                                                    <span>
                                                        {currencyFormat(cardFormik.values.amt)}
                                                    </span>
                                                </li>
                                                <li className="divider"></li>
                                                <li>
                                                    <span>
                                                        <strong>{t('IVA 21%')} </strong>
                                                    </span>
                                                    <span>
                                                        {currencyFormat(
                                                            (cardFormik.values.amt * 21) / 100,
                                                        )}
                                                    </span>
                                                </li>

                                                <li className="divider"></li>
                                                <li>
                                                    <span>
                                                        <strong>{t('total')} :</strong>
                                                    </span>
                                                    {/* {membership ? (
                                                    <span>
                                                        {user.membership_period === 'monthly' ? (
                                                            <>
                                                                {currencyFormat(
                                                                    cardFormik.values.amt -
                                                                        membership.monthly_deposit_amount,
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {currencyFormat(
                                                                    cardFormik.values.amt -
                                                                        membership.yearly_deposit_amount,
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                ) : ( 
                                                    <span>
                                                        {currencyFormat(cardFormik.values.amt)}
                                                    </span>
                                                )} */}
                                                    <span>
                                                        {currencyFormat(
                                                            cardFormik.values.amt +
                                                                (cardFormik.values.amt * 21) / 100,
                                                        )}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <div className="rtImg">
                                        <img src="/assets/images/crown.png" />
                                    </div> */}
                                    </div>
                                </div>
                            ) : null}
                            <div className="subcardAdding">
                                <div className="d-flex align-items-center justify-content-end mb-4">
                                    <SecondaryButton
                                        label={
                                            <>
                                                <span className="material-icons">add</span>
                                                {t('add_new_card')}
                                            </>
                                        }
                                        onClick={() => {
                                            toggleFullScreenCardPopup(true, 'new', null)
                                        }}
                                        id="subscription_add_new_card"
                                    />
                                    {/* <SecondaryButton
                                    btnSize="ml-2"
                                    label={
                                        <>
                                            <span className="material-icons">add</span>
                                            Add New Bank
                                        </>
                                    }
                                    onClick={() => {
                                        toggleFullScreenBankPopup(true, 'new', null)
                                    }}
                                /> */}
                                </div>
                                {cardFormik.values.planName ? (
                                    <>
                                        <div className="chCnt chCntGrid">
                                            {console.log('st----->', cards)}
                                            {cards &&
                                                cards.map((data, index) => (
                                                    <div className="selectAddress  d-flex justify-content-start align-items-center">
                                                        <input
                                                            name="cards"
                                                            value={data.id}
                                                            onChange={() => handleSelect(data)}
                                                            type="radio"
                                                            id={`card_${index}`}
                                                            hidden
                                                        ></input>
                                                        <label htmlFor={`card_${index}`}>
                                                            <ListItem button>
                                                                {data.id ===
                                                                cardFormik.values.cards ? (
                                                                    <span className="material-icons">
                                                                        radio_button_checked
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        id="unchecked"
                                                                        className="material-icons"
                                                                    >
                                                                        radio_button_unchecked
                                                                    </span>
                                                                )}
                                                                <div
                                                                    className="savedAddressCard"
                                                                    key={index}
                                                                >
                                                                    <div>
                                                                        <h5>
                                                                            {data.brand}{' '}
                                                                            {t('ending in')}{' '}
                                                                            {data.last4}
                                                                        </h5>
                                                                        <h5>
                                                                            EXP. {data.exp_month}/
                                                                            {data.exp_year}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </ListItem>
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>

                                        <div className="chCnt chCntGrid">
                                            {banks &&
                                                banks.map((data, index) => (
                                                    <div className="selectAddress  d-flex justify-content-start align-items-center">
                                                        <input
                                                            name="cards"
                                                            value={data.id}
                                                            onChange={() => handleSelect(data)}
                                                            type="radio"
                                                            id={`bank_${index}`}
                                                            hidden
                                                        ></input>
                                                        <label htmlFor={`bank_${index}`}>
                                                            <ListItem button>
                                                                {data.id ===
                                                                cardFormik.values.cards ? (
                                                                    <span className="material-icons">
                                                                        radio_button_checked
                                                                    </span>
                                                                ) : (
                                                                    <span className="material-icons">
                                                                        radio_button_unchecked
                                                                    </span>
                                                                )}
                                                                <div
                                                                    className="savedAddressCard"
                                                                    key={index}
                                                                >
                                                                    <div>
                                                                        <h5>{data.bank_name}</h5>
                                                                        <h5>
                                                                            {data.last4}/
                                                                            {data.routing_number}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </ListItem>
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>
                                        <div className="mt-4">
                                            <PrimaryButton
                                                onClick={() => cardFormik.handleSubmit()}
                                                label={t('submit')}
                                                type="submit"
                                                disabled={paymentLoading}
                                                id="payment_submit"
                                            />
                                        </div>
                                        {/* <form onSubmit={cardFormik.handleSubmit}>
                                        {cardFormik.values.membership !== 'free' ? (
                                            <>
                                                <h5 className="paymentMethods mb-4">Pay with card</h5>
                                                <CardDetails
                                                    data={manageCard}
                                                    function={toggleFullScreenCardPopup}
                                                    // data={{
                                                    //     popup: false,
                                                    //     status: 'new',
                                                    //     data: null,
                                                    // }}
                                                />

                                                <div className="paymentform">
                                                    <div className="row">{mapData(cardDetails)}</div>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <div className="registerviw">
                                            <PrimaryButton
                                                label="Submit"
                                                type="submit"
                                                disabled={paymentLoading}
                                            />
                                            {paymentLoading ? (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            ) : null}
                                        </div>
                                    </form> */}
                                    </>
                                ) : null}
                            </div>
                        </div>
                        {/* <SkyflowCardDetails data={manageCard} function={toggleFullScreenCardPopup} /> */}
                        <MyPaymentComponent
                            allCards={cards}
                            data={manageCard}
                            function={toggleFullScreenCardPopup}
                            from="custom_card"
                        />
                        <BankDetails data={manageBank} function={toggleFullScreenBankPopup} />
                    </CustomDialog>
                </div>
            </div>
        </>
    )
}
