import React, { useRef, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { InputAdornment, IconButton } from '@material-ui/core'
import CustomDialog from '../../organisms/Dialog/index'

const CustomInput = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    const textFieldRef = useRef(null)
    const [toggleDialog, setToggleDialog] = useState(false)
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }
    useEffect(() => {
        if (props.onWheel) {
            const handleWheel = (e) => e.preventDefault()
            textFieldRef.current.addEventListener('wheel', handleWheel)
        }

        // return () => {
        //     if (props.onWheel) {
        //         textFieldRef.current.removeEventListener('wheel', handleWheel)
        //     }
        // }
    }, [props.onWheel])
    return (
        <div
            className={`customInput ${props.error ? 'error-input' : ''}`}
            style={props.inputStyle ? props.inputStyle : null}
        >
            {props.upperLabel ? (
                <>
                    <label htmlFor={props.id}>
                        {props.label}
                        {props?.lableInfo ? (
                            <Tooltip title={props?.tooltiptitle}>
                                <IconButton>
                                    <span
                                        className="material-icons"
                                        onClick={() => {
                                            if (props?.contents) {
                                                setToggleDialog(true)
                                            }
                                        }}
                                    >
                                        info
                                    </span>
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </label>

                    <TextField
                        value={props.value}
                        autoFocus={props.autoFocus}
                        ref={textFieldRef}
                        name={props.name}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        InputProps={{
                            inputProps: props.inputProps,
                            startAdornment: props.startAdornment,
                            endAdornment: props.endAdornment,
                            shrink: shrink,
                        }}
                        id={props.id}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        onKeyDown={props.onKeyDown}
                        variant={props.variant ? props.variant : 'outlined'}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                    />
                </>
            ) : props?.endAdornment === 'info' ? (
                <>
                    {' '}
                    <TextField
                        value={props.value}
                        autoFocus={props.autoFocus}
                        ref={textFieldRef}
                        name={props.name}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        InputProps={{
                            inputProps: props.inputProps,
                            startAdornment: props.startAdornment,
                            endAdornment:
                                props.endAdornment === 'info' ? (
                                    <InputAdornment position="end">
                                        <Tooltip title={props.tooltiptitle}>
                                            <IconButton>
                                                <span className="material-icons">info</span>
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ) : (
                                    props.endAdornment
                                ),

                            shrink: shrink,
                            'aria-label': props.label,
                        }}
                        InputLabelProps={{
                            id: props.id,
                            name: props.label,
                            'aria-label': props.label,
                            'aria-labelledby': props.id,
                        }}
                        htmlFor={props.id}
                        id={props.id}
                        label={props.label}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        onKeyDown={props.onKeyDown}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                    />{' '}
                </>
            ) : (
                <Tooltip
                    title={props.tooltiptitle}
                    arrow={props.tooltiptitle ? true : false}
                    placement="top"
                    disableHoverListener={
                        props.disableHoverListener ? props.disableHoverListener : true
                    }
                    disableFocusListener={props.tooltiptitle ? false : true}
                >
                    <TextField
                        value={props.value}
                        autoFocus={props.autoFocus}
                        ref={textFieldRef}
                        name={props.name}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        InputProps={{
                            inputProps: props.inputProps,
                            startAdornment: props.startAdornment,
                            endAdornment: props.endAdornment,

                            shrink: shrink,
                            'aria-label': props.label,
                        }}
                        InputLabelProps={{
                            id: props.id,
                            name: props.label,
                            'aria-label': props.label,
                            'aria-labelledby': props.id,
                        }}
                        htmlFor={props.id}
                        id={props.id}
                        label={props.label}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        onKeyDown={props.onKeyDown}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                    />
                </Tooltip>
            )}

            <CustomDialog
                title={
                    <div className="bidConfirmTitle">
                        {props?.tooltiptitle}
                        <div className="ssmHead d-flex justify-content-center">
                            <button
                                type="button"
                                className="close"
                                id="cancel"
                                onClick={() => setToggleDialog(false)}
                            >
                                <span className="material-icons">clear</span>
                            </button>
                        </div>
                    </div>
                }
                open={toggleDialog}
                function={changeDialogStatus}
                className="bidConfirmationCstm"
            >
                <div className="">
                    {props?.contents
                        ? props?.contents
                              .replace(/\$enter/g, '\n')
                              .split('\n')
                              .map((line, index) => <p key={index}>{line}</p>)
                        : null}
                </div>
            </CustomDialog>
        </div>
    )
}

export default CustomInput
